import React from 'react';
import ServiceCard from './subComponents/ServiceCard';
import temp1 from '../assets/images/temp1.jpeg'
import temp2 from '../assets/images/temp2.jpeg'
import temp3 from '../assets/images/temp3.jpeg'
import temp4 from '../assets/images/temp4.png'

const Services = () => {
    // const [showMore, setShowMore] = useState(false);

    const services = [
        { title: 'Roof Repairs', description: 'Addressing issues promptly to prevent further damage and extend the life of your roof.', image: temp1 },
        { title: 'Roof Replacements', description: 'Comprehensive roof replacement services using the latest materials and techniques.', image: temp2 },
        { title: 'Comercial Roofing', description: 'Specialized solutions tailored to the unique needs of commercial properties.', image: temp3 },
        { title: 'Residential Roofing', description: 'Expert services designed to protect and enhance your home’s roofing system.', image: temp4 },

    ];

    // const handleShowMore = () => {
    //     setShowMore(!showMore);
    // };

    return (
        <div id='services' className="py-12 bg-white flex justify-center items-center w-full">
            <div className='mx-4'>
                <h2 className="text-center text-3xl md:text-4xl font-bold text-primary-blue">What We Do</h2>
                <h3 className='text-center text-sm md:text-md mt-4'>
                    MI Roof Pro delivers high-quality roofing services with transparent pricing and clear
                    communication.
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-16 mt-4">
                    {/* {services.slice(0, showMore ? services.length : 4).map((service, index) => (
                        <ServiceCard key={index} service={service} />
                    ))} */}
                    {services.map((service, index) => (
                        <ServiceCard key={index} service={service} />
                    ))}
                </div>
                {/* <div className="text-center mt-4 mx-w-xl">
                    <button onClick={handleShowMore} className="bg-primary-blue text-sm text-white py-2 px-8 rounded-sm font-semibold">
                        {showMore ? 'HIDE SERVICES' : 'VIEW MORE SERVICES'}
                    </button>
                </div> */}
            </div>
        </div>
    );
}

export default Services;
